// /** @type {import('next').NextConfig} */
const nextConfig = {
  productionBrowserSourceMaps: true,
  reactStrictMode: false,
  images: {
    domains: ["s3.eu-west-3.amazonaws.com", "www.centralholidays.com"],
  },
  companyId: "61d58047cd155a856c9d4848",
  accessToken:
    "eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGRzaWctbW9yZSNobWFjLXNoYTI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI2NWFmY2YyYTJhNDQzMmMyOWVlZmM3NzgiLCJlbWFpbCI6IndlYnNpdGVAc2hlcnphbC5jb20iLCJuYW1lIjoiV0VCU0lURSBVU0VSIiwianRpIjoiMDk3ZWY4MTQtOTQ1MS00NTU1LWJlNzktMTI2NWI4MDdhMzE5IiwiVXNlcklkIjoiNjVhZmNmMmEyYTQ0MzJjMjllZWZjNzc4IiwiZXhwIjoyMTQ1NjIxODE0LCJpc3MiOiJTSEVSWkFMIiwiYXVkIjoiU0hFUlpBTCJ9.ZLTmLsB06RXR4F8Ki0zYGm9vc2RggGtxqp62jxWIQts",
  // accessToken:
  //   "eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGRzaWctbW9yZSNobWFjLXNoYTI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI2MjFjYTQwOGI3MTI5NDZlZDhlN2YxZGEiLCJlbWFpbCI6ImhpZGRlbnVzZXJAbWFpbC5jb20iLCJuYW1lIjoiRWxhc3RpYyBUcmF2ZWwiLCJqdGkiOiI0MDIyYjAxYi1lMDY0LTQxYWQtOWYxMC1hYmNiNGM4N2IwYzkiLCJVc2VySWQiOiI2MjFjYTQwOGI3MTI5NDZlZDhlN2YxZGEiLCJleHAiOjE3MDg2MDk0NjksImlzcyI6IlNIRVJaQUwiLCJhdWQiOiJTSEVSWkFMIn0.kah2oi1xzwSd7HOaKWIA47qemPai3qTAF7LpB-xDzSc",
  apiURL: "https://chtg-api.elastic.travel/api/",
  googleAPIKey: "AIzaSyAicsoGlftseqfMAAftBsxeMTnNc9PaEvM",
};

module.exports = nextConfig;
