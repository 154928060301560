import axios from "axios";
import nextConfig from "../next.config";
import { refreshTokenFn } from "./public";

const axiosconfig = axios.create({
  baseURL: "https://chtg-api.elastic.travel/api/",
});

// const axiosconfig = axios.create({
//   baseURL: "https://demo-api.elastic.travel/api/",
// });

axiosconfig.interceptors.request.use(
  async (config) => {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${nextConfig.accessToken}`,
    };
    config.params = {
      ...config.params,
      CompanyId: nextConfig.companyId,
    };
    // let token;
    // let companyId;
    // if (typeof window !== "undefined") {
    //   token = sessionStorage.getItem("Token");
    //   companyId = sessionStorage.getItem("CompanyId");
    // }
    // if (token && companyId) {
    //   config.headers = {
    //     ...config.headers,
    //     Authorization: `Bearer ${token}`,
    //   };
    //   config.params = {
    //     ...config.params,
    //     CompanyId: companyId,
    //   };
    // } else {
    //   const result = await refreshTokenFn();
    //   config.headers = {
    //     ...config.headers,
    //     Authorization: `Bearer ${result?.token}`,
    //   };
    //   config.params = {
    //     ...config.params,
    //     CompanyId: result?.companyId,
    //   };
    // }
    return config;
  },
  (error) => Promise.reject(error)
);

export const axiosUploadConfig = axios.create({
  baseURL: "https://chtg-api.elastic.travel/",
});

axiosUploadConfig.interceptors.request.use(
  async (config) => {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${nextConfig.accessToken}`,
    };
    config.params = {
      ...config.params,
      CompanyId: nextConfig.companyId,
    };
    // let token;
    // let companyId;
    // if (typeof window !== "undefined") {
    //   token = sessionStorage.getItem("Token");
    //   companyId = sessionStorage.getItem("CompanyId");
    // }
    // if (token && companyId) {
    //   config.headers = {
    //     ...config.headers,
    //     Authorization: `Bearer ${token}`,
    //   };
    //   config.params = {
    //     ...config.params,
    //     CompanyId: companyId,
    //   };
    // } else {
    //   const result = await refreshTokenFn();
    //   config.headers = {
    //     ...config.headers,
    //     Authorization: `Bearer ${result?.token}`,
    //   };
    //   config.params = {
    //     ...config.params,
    //     CompanyId: result?.companyId,
    //   };
    // }

    return config;
  },
  (error) => Promise.reject(error)
);

export default axiosconfig;
