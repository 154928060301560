/* eslint-disable @next/next/next-script-for-ga */
/* eslint-disable @next/next/no-sync-scripts */
import Header from "../components/Header";
import Footer from "../components/Footer";
import ScrolltoTop from "../components/scrollTop";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "../styles/custom.css";
import "../styles/style.css";
import { useEffect, useState } from "react";
import HomeLoading from "../components/layout/Home.loading";
import axiosconfig from "../config/axios-config";
import nextConfig from "../next.config";
import Cookies from "js-cookie";
import NextNProgress from "nextjs-progressbar";
import Head from "next/head";

function MyApp({ Component, pageProps, navigationProps }) {
  const [homeLoading, setHomeLoading] = useState(true);
  useEffect(() => {
    if (typeof window !== "undefined") {
      if (window.location?.search?.includes("utm")) {
        const params = new URLSearchParams(window.location.search);
        const UtmObject = {
          url: window.location.href,
          utm_source: params.get("utm_source"),
          utm_campaign: params.get("utm_campaign"),
          utm_medium: params.get("utm_medium"),
          utm_content: params.get("utm_content"),
        };
        const jsonUTMObject = JSON.stringify(UtmObject);
        Cookies.set("myCookieUtms", jsonUTMObject, { expires: 48 });
      }
      if (
        window.document.referrer &&
        !window.document.referrer.includes(window.location.origin)
      ) {
        Cookies.set("myCookieReferrer", window.document.referrer, {
          expires: 48,
        });
      }
    }
  }, []);
  useEffect(() => {
    setTimeout(() => {
      setHomeLoading(false);
    }, 1200);
  }, []);
  return (
    <>
      <Head>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-XEF38KJ044"
        ></script>
        <script
          dangerouslySetInnerHTML={{
            __html: `
window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', 'G-XEF38KJ044');
 `,
          }}
        />
      </Head>
      <NextNProgress color="#df1b23" />
      <Header nav={navigationProps} />
      <HomeLoading loading={homeLoading} />
      <Component {...pageProps} />
      <Footer nav={navigationProps} />
      <ScrolltoTop />
    </>
  );
}
let navigationPropsCache;
MyApp.getInitialProps = async () => {
  // if (navigationPropsCache) {
  //   return { navigationProps: navigationPropsCache };
  // }
  // Returns Company Logo and other info
  const resCompany = await axiosconfig.get(
    `admin/company/${nextConfig.companyId}`
  );
  // Returns the links with header and footer and external link props
  const resPage = await axiosconfig.get("website/pagemodule", {
    params: {
      PageSize: 1000,
      CompanyId: nextConfig.companyId,
    },
  });
  // Sort the links returned by the request
  const pageData = await resPage?.data?.data
    ?.filter((link) => link.isActive)
    ?.sort((a, b) => a.headerOrder - b.headerOrder);
  // Return page containers with module id and submodule id, and seo info
  // Return if page is external link with its url, return if page is destination page.
  const resCon = await axiosconfig.get("website/pagecontainer/mini", {
    params: {
      PageSize: 1000,
      CompanyId: nextConfig.companyId,
    },
  });
  // get website destination info (show in nav, in home page, order in home page, cover photo, id, cid)
  const resDest = await axiosconfig.get("website/websitedestination/mini", {
    params: {
      PageSize: 1000,
      CompanyId: nextConfig.companyId,
    },
  });
  // Return regions (that have packages) with country names and countries ids
  const resRegion = await axiosconfig.get(
    `foundation/region/regionswithpackages/${nextConfig.companyId}`
  );
  // Return company contact info, emails, phones, fax and address
  const resContact = await axiosconfig.get("website/contactus", {
    params: {
      PageSize: 1000,
      PageNumber: 1,
      CompanyId: nextConfig.companyId,
    },
  });
  // Return company social media links with order
  const resSocial = await axiosconfig.get("website/socialmedialink", {
    params: {
      PageSize: 1000,
      PageNumber: 1,
      CompanyId: nextConfig.companyId,
    },
  });
  // Set navigation props.
  const pagecontainerData = await resCon?.data?.data;
  const contactus = await resContact?.data?.data[0];
  const socialMedia = await resSocial?.data?.data;
  const reginData = await resRegion?.data?.data;
  const destination = await resDest?.data?.data;
  const company = await resCompany?.data?.data;
  const navigationProps = {
    pageData,
    pagecontainerData,
    contactus,
    socialMedia,
    destination, // return destination info with show in header, order, show in home page
    reginData,
    company,
  };
  navigationPropsCache = navigationProps;
  return { navigationProps };
};

export default MyApp;
